import React, { useState, useEffect } from "react";
import { Paper, Table, TableContainer } from "@mui/material";
import {
  Navigation,
  PageHeader,
  PositionTableBody,
  PositionTableFilter,
  TableHeader,
} from "../components";
import { useGetSignalsListQuery } from "../store/services/apa.api";
import createColumnData from "../utils/stages/createColumnData";
import { PAGINATION_LIMIT } from "../utils/consts";
import "../styles/base.scss";

const columns = [
  createColumnData("Stage", "stage_name", "stage.name", true),
  createColumnData("Date", "created_at", "signal.created_at", true),
  createColumnData("S-name", "strategy", "signal.strategy", true),
  createColumnData("S-Dir", "direction", "signal.direction", true),
  createColumnData("Signal ID", "signal_id", "signal.signal_id", true),
  createColumnData("Ticker", "ticker", "signal.ticker", true),
  createColumnData(
    "P-S-$",
    "position_size_usd",
    "signal.position_size_usd",
    true,
  ),
  createColumnData(
    "P-S-C",
    "position_size_coins",
    "signal.position_size_coins",
    true,
  ),
  createColumnData("R-PnL$", "r_pnl_usd", "signal.r_pnl_usd", true),
  createColumnData("U-PnL$", "u_pnl_usd", "signal.u_pnl_usd", true),
  createColumnData(
    "E.Price",
    "avg_enter_price",
    "signal.avg_enter_price",
    true,
  ),
  createColumnData("kExit", "k_exit", "signal.k_exit", true),
  createColumnData(
    "kExit coeff",
    "k_exit_coefficient",
    "signal.k_exit_coefficient",
    true,
  ),
  createColumnData("C.Price", "current_price", "signal.current_price", true),
  createColumnData("M.Price", "mark_price", "signal.mark_price", true),
  createColumnData("TRinATR", "tr_in_atr", "signal.tr_in_atr", true),
  createColumnData("Inc.Vola", "inc_vola", "signal.inc_vola", true),
  createColumnData(
    "ATR(i-1) (%)",
    "atr_i_minus_1_percent",
    "signal.atr_i_minus_1_percent",
    true,
  ),
  createColumnData("ATR-T (%)", "atr_t_percent", "signal.atr_t_percent", true),
  createColumnData("Exec", "execution", "signal.execution", true),
  createColumnData("Id", "id", "signal.id", false),
];

const ActivePositionAdministrationPage = () => {
  const paginationLimit = PAGINATION_LIMIT;

  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState("");
  const [excludedCells, setExcludedCells] = useState([]);

  const [queryName, setQueryName] = useState([]);
  const [queryClient, setQueryClient] = useState([]);
  const [queryTicker, setQueryTicker] = useState([]);
  const [queryStrategy, setQueryStrategy] = useState([]);
  const [queryDirection, setQueryDirection] = useState("");
  const [queryExchange, setQueryExchange] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  const { data, isLoading, isFetching } = useGetSignalsListQuery({
    paginationLimit,
    offset,
    orderBy,
    name: queryName.map((i) => i.id),
    client: queryClient.map((i) => i.id),
    ticker: queryTicker.map((i) => i.name),
    strategy: queryStrategy.map((i) => i.name),
    direction: queryDirection,
    exchange: queryExchange,
  });

  const handleSelectRow = (selectedRow) => {
    const isSelected = selectedRows.find(
      (currRow) => currRow.id === selectedRow.id,
    );

    if (isSelected) {
      setSelectedRows((prevRows) =>
        prevRows.filter((prevRow) => prevRow.id !== selectedRow.id),
      );
    } else {
      setSelectedRows((prevRows) => [...prevRows, selectedRow]);
    }
  };

  const handleCellExclusion = (excludedCell) => {
    if (excludedCells?.includes(excludedCell)) {
      setExcludedCells(excludedCells.filter((cell) => cell !== excludedCell));
    } else {
      setExcludedCells([...excludedCells, excludedCell]);
    }
  };

  const filteredColumns = columns.filter(
    (item) => !excludedCells.includes(item.param),
  );

  const orderedRows = data?.rows?.map((row) => {
    const orderedRow = {};
    columns.forEach((column) => {
      orderedRow[column.param] = row[column.param];
    });
    return orderedRow;
  });

  const filteredRows = orderedRows?.map((obj) =>
    Object.fromEntries(
      Object.entries(obj).filter(([key]) => !excludedCells.includes(key)),
    ),
  );

  const filteredSummary = Object.fromEntries(
    Object.entries(data?.summary || {}).filter(
      ([key]) => !excludedCells.includes(key.substring(4)),
    ),
  );

  useEffect(() => {
    const tableContainer = document.getElementById("table");

    const onScroll = () => {
      const scrolledToBottom =
        tableContainer.scrollTop + tableContainer.clientHeight >=
        tableContainer.scrollHeight - 1;
      if (scrolledToBottom && !isFetching) {
        if (data.isNextPage) {
          setOffset((prevOffset) => prevOffset + paginationLimit);
        }
      }
    };

    tableContainer.addEventListener("scroll", onScroll);

    return function () {
      tableContainer.removeEventListener("scroll", onScroll);
    };
  }, [offset, isFetching]);

  const handleSetQueryName = (value) => {
    setOffset(0);
    setQueryName(value);
  };

  const handleSetQueryClient = (value) => {
    setOffset(0);
    setQueryClient(value);
  };

  const handleSetQueryTicker = (value) => {
    setOffset(0);
    setQueryTicker(value);
  };

  const handleSetQueryStrategy = (value) => {
    setOffset(0);
    setQueryStrategy(value);
  };

  const handleSetQueryDirection = (value) => {
    setOffset(0);
    setQueryDirection(value);
  };

  const handleSetQueryExchange = (value) => {
    setOffset(0);
    setQueryExchange(value);
  };

  const handleSetOrderBy = (value) => {
    setOffset(0);
    setOrderBy(value);
  };

  const tableWrapperStyle = {
    maxHeight: `calc(100vh - ${94 + 71}px)`,
  }

  return (
    <Navigation>
      <PageHeader title={"Active position administration (APA)"} />

      <TableContainer
        container={Paper}
        style={{ width: "calc(100vw - 100px)" }}
      >
        <PositionTableFilter
          cells={columns}
          excludedCells={excludedCells}
          setCellExclusion={handleCellExclusion}
          setSearchByName={handleSetQueryName}
          setSearchByClient={handleSetQueryClient}
          setSearchByTicker={handleSetQueryTicker}
          setSearchByStrategy={handleSetQueryStrategy}
          setSearchByDirection={handleSetQueryDirection}
          setSearchByExchange={handleSetQueryExchange}
          selectedRows={selectedRows}
        />
        <div style={tableWrapperStyle} className="sticky-table-wrapper" id="table">
          <Table sx={{ maxWidth: "calc(100vw - 100px)" }}>
            <TableHeader
              columns={filteredColumns}
              orderBy={orderBy}
              setOrderBy={handleSetOrderBy}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allRows={data?.rows}
              isHaveCheckbox
            />
            <PositionTableBody
              rows={filteredRows}
              selectedRows={selectedRows}
              onSelectRow={handleSelectRow}
              columns={filteredColumns}
              summary={filteredSummary}
              isLoading={isLoading || isFetching}
            />
          </Table>
        </div>
      </TableContainer>
    </Navigation>
  );
};

export default ActivePositionAdministrationPage;
