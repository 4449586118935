import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import { TableFilter, FilterDropdown, FilterButton } from "../../";
import { FilterIcon, GridIcon } from "../../../assets";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateRangeInput from "./DateRangeInput";
import { format } from "date-fns";

const HistoryTableFilter = ({
  cells,
  setCellExclusion,
  setStartDateFilter,
  setEndDateFilter,
  excludedCells,
}) => {
  const [openFilter, setOpenFilter] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const applyFilters = useCallback(() => {
    setStartDateFilter(format(startDate, "yyyy-MM-dd'T'HH:mm:ss"));
    setEndDateFilter(format(endDate, "yyyy-MM-dd'T'HH:mm:ss"));
  }, [startDate, endDate]);

  const clearFilters = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
    setStartDateFilter(null);
    setEndDateFilter(null);
  }, [startDate, endDate]);

  return (
    <>
      <TableFilter>
        <Grid item xs />
        <Grid item>
          <FilterButton
            onClick={() => setOpenFilter((prevState) => !prevState)}
            icon={<img src={FilterIcon} alt="checkbox" />}
          />
        </Grid>
        <Grid item>
          <FilterDropdown
            getSelected={setCellExclusion}
            excludedCells={excludedCells}
            options={cells}
            icon={<img src={GridIcon} alt="grid icon" />}
          />
        </Grid>
      </TableFilter>
      <TableFilter
        style={{ borderRadius: 0, display: openFilter ? "flex" : "none" }}
      >
        <Grid item xs />
        <Grid item>
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            placeholderText={"dd/mm/yyyy - dd/mm/yyyy"}
            dateFormat="yyyy/MM/dd"
            selectsRange
            customInput={<DateRangeInput />}
          />
        </Grid>

        <Grid item sx={{ textAlign: "end" }}>
          <Button variant="outlined" onClick={clearFilters} sx={{ mr: 2 }}>
            Clear
          </Button>
          <Button
            variant="contained"
            onClick={applyFilters}
            disabled={!(startDate && endDate)}
          >
            Apply
          </Button>
        </Grid>
      </TableFilter>
    </>
  );
};

HistoryTableFilter.propTypes = {
  cells: PropTypes.array,
  setCellExclusion: PropTypes.func,
  setStartDateFilter: PropTypes.func,
  setEndDateFilter: PropTypes.func,
  excludedCells: PropTypes.array,
};

export default HistoryTableFilter;
