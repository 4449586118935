import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import {
  TableFilter,
  InputSelect,
  FilterDropdown,
  InputMultiselect,
  FilterButton,
} from "..";
import { useSearchStageByNameQuery } from "../../store/services/amd.api";
import { FilterIcon, GridIcon } from "../../assets";
import { optionsExchange } from "../../utils/consts";
import ClientFilterInput from "../ClientFilterInput";

const initialFilters = {
  selectedStages: [],
  selectedClients: [],
  selectedExchange: "",
};

const AdministrationTableFilter = ({
  setFilterStage,
  setFilterClient,
  setFilterExchange,
  cells,
  setCellExclusion,
  excludedCells,
}) => {
  const [queryStage, setQueryStage] = useState("");
  const [openFilter, setOpenFilter] = useState(false);

  const [filters, setFilters] = useState(initialFilters);

  const toggleFilter = () => {
    setOpenFilter((prevState) => !prevState);
  };

  const getStagesName = useSearchStageByNameQuery({ name: queryStage });

  const onSearchStage = useCallback((value) => setQueryStage(value || ""), []);
  const onSelectStages = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedStages: value,
      })),
    [],
  );

  const onSelectClient = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedClients: value,
      })),
    [],
  );

  const onSearchExchange = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedExchange: value?.name || "",
      })),
    [],
  );

  const optionsStage = getStagesName?.data
    ? getStagesName?.data?.rows?.map((item) => ({ label: item.name, ...item }))
    : [];

  const applyFilters = useCallback(() => {
    setFilterStage(filters.selectedStages);
    setFilterClient(filters.selectedClients);
    setFilterExchange(filters.selectedExchange);
  }, [filters, setFilterStage, setFilterClient, setFilterExchange]);

  const clearFilters = useCallback(() => {
    setFilters(initialFilters);
    setFilterStage([]);
    setFilterClient([]);
    setFilterExchange("");
  }, [setFilterStage, setFilterClient, setFilterExchange]);

  const getFilterExchange = useCallback(() => {
    if (filters.selectedExchange) {
      return optionsExchange.find((o) => o.name === filters.selectedExchange)?.label
    }
    return null;
  }, [filters]);

  return (
    <>
      <TableFilter>
        <Grid item xs />
        <Grid item>
          <FilterButton
            onClick={toggleFilter}
            icon={<img src={FilterIcon} alt="checkbox" />}
          />
        </Grid>
        <Grid item>
          <FilterDropdown
            getSelected={setCellExclusion}
            excludedCells={excludedCells}
            options={cells}
            icon={<img src={GridIcon} alt="grid icon" />}
          />
        </Grid>
      </TableFilter>
      <TableFilter
        style={{ borderRadius: 0, display: openFilter ? "flex" : "none" }}
      >
        <Grid item xs>
          <InputMultiselect
            loading={getStagesName.isLoading || getStagesName.isFetching}
            options={optionsStage}
            label={"Stages"}
            handleInputChange={onSearchStage}
            getSelected={onSelectStages}
            value={filters.selectedStages}
          />
        </Grid>
        <Grid item xs>
          <ClientFilterInput
            getSelected={onSelectClient}
            value={filters.selectedClients}
          />
        </Grid>
        <Grid item xs>
          <InputSelect
            options={optionsExchange}
            label={"Exchange"}
            defaultValue={null}
            value={getFilterExchange()}
            getSelected={onSearchExchange}
          />
        </Grid>
        <Grid item xs />
        <Grid item>
          <Button variant="outlined" onClick={clearFilters}>
            Clear All
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={applyFilters}>
            Apply
          </Button>
        </Grid>
      </TableFilter>
    </>
  );
};

AdministrationTableFilter.propTypes = {
  setFilterStage: PropTypes.func,
  setFilterClient: PropTypes.func,
  setFilterExchange: PropTypes.func,
  cells: PropTypes.array,
  setCellExclusion: PropTypes.func,
  excludedCells: PropTypes.array,
};

export default AdministrationTableFilter;
