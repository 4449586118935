import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import { TableFilter, InputSelect, InputMultiselect, FilterButton } from "..";
import { FilterIcon } from "../../assets";
import { useSearchStageByNameQuery } from "../../store/services/client.api";
import { optionsExchange, optionsTrading } from "../../utils/consts";

const initialFilters = {
  selectedStages: [],
  selectedClients: [],
  selectedExchange: "",
  selectedTrading: "",
};

const ClientStagesTableFilter = ({
  id,
  setFilterStage,
  setFilterExchange,
  setFilterTrading,
}) => {
  const [openFilter, setOpenFilter] = useState(false);
  const toggleFilter = useCallback(
    () => setOpenFilter((prevState) => !prevState),
    [],
  );
  const [queryStage, setQueryStage] = useState("");
  const [filters, setFilters] = useState(initialFilters);

  const getStagesName = useSearchStageByNameQuery({ id, name: queryStage });

  const onSearchStage = useCallback((value) => setQueryStage(value || ""), []);
  const onSelectStage = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedStages: value || [],
      })),
    [],
  );

  const onSelectExchange = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedExchange: value?.name || "",
      })),
    [],
  );

  const onSelectTrading = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedTrading: value?.name || "",
      })),
    [],
  );

  const optionsStage = useMemo(
    () =>
      getStagesName?.data
        ? getStagesName.data.map((item) => ({ label: item.name, ...item }))
        : [],
    [getStagesName],
  );

  const applyFilters = useCallback(() => {
    setFilterStage(filters.selectedStages);
    setFilterExchange(filters.selectedExchange);
    setFilterTrading(filters.selectedTrading);
  }, [filters, setFilterStage, setFilterExchange]);

  const clearFilters = useCallback(() => {
    setFilters(initialFilters);
    setFilterStage([]);
    setFilterExchange("");
    setFilterTrading("");
  }, [setFilterStage, setFilterExchange]);

  const getFilterExchange = useCallback(() => {
    if (filters.selectedExchange) {
      return optionsExchange.find((o) => o.name === filters.selectedExchange)?.label
    }
    return null;
  }, [filters]);

  const getFilterTradingStatus = useCallback(() => {
    if (filters.selectedTrading) {
      return optionsTrading.find((o) => o.name === filters.selectedTrading)?.label
    }
    return null;
  }, [filters]);

  return (
    <>
      <TableFilter>
        <Grid item xs />
        <Grid item>
          <FilterButton
            onClick={toggleFilter}
            icon={<img src={FilterIcon} alt="checkbox" />}
          />
        </Grid>
      </TableFilter>
      <TableFilter
        style={{ borderRadius: 0, display: openFilter ? "flex" : "none" }}
      >
        <Grid item>
          <InputMultiselect
            loading={getStagesName.isLoading || getStagesName.isFetching}
            options={optionsStage}
            label={"Stage name"}
            value={filters.selectedStages}
            handleInputChange={onSearchStage}
            getSelected={onSelectStage}
          />
        </Grid>
        <Grid item>
          <InputSelect
            options={optionsExchange}
            label={"Exchange"}
            defaultValue={null}
            value={getFilterExchange()}
            getSelected={onSelectExchange}
          />
        </Grid>
        <Grid item>
          <InputSelect
            options={optionsTrading}
            label={"Trading status"}
            defaultValue={null}
            value={getFilterTradingStatus()}
            getSelected={onSelectTrading}
          />
        </Grid>
        <Grid item xs />
        <Grid item>
          <Button variant="outlined" onClick={clearFilters}>
            Clear All
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={applyFilters}>
            Apply
          </Button>
        </Grid>
      </TableFilter>
    </>
  );
};

ClientStagesTableFilter.propTypes = {
  id: PropTypes.string,
  setFilterStage: PropTypes.func,
  setFilterExchange: PropTypes.func,
  setFilterTrading: PropTypes.func,
  selectedRows: PropTypes.array,
  cells: PropTypes.array,
  setCellExclusion: PropTypes.func,
  excludedCells: PropTypes.array,
};

export default ClientStagesTableFilter;
