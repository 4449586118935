import React, { useEffect, useState } from "react";
import { Paper, Table, TableContainer } from "@mui/material";
import {
  ClientStagesBreadcrumbs,
  ClientStagesTableBody,
  ClientStagesTableFilter,
  ClientStagesTabs,
  Loader,
  Navigation,
  TableHeader,
} from "../components";
import { createColumnData } from "../utils/stages";
import { useGetStagesListQuery } from "../store/services/sss.api";
import { useParams } from "react-router-dom";
import ClientEditPage from "../components/clients/ClientEditPage";
import { PAGINATION_LIMIT } from "../utils/consts";
import { useGetClientByIdQuery } from "../store/services/client.api";
import { COLORS } from "../styles/colors";

const columns = [
  createColumnData("Stage id", "id", "stage.id", true),
  createColumnData("Stage name", "name", "stage.name", true),
  createColumnData("Portfolio", "portfolio", "stage.portfolio", true),
  createColumnData("Exchange", "exchange", "stage.exchange", true),
  createColumnData(
    "Trading status",
    "trading_status",
    "stage.trading_status",
    false,
  ),
  createColumnData(
    "System status",
    "system_status",
    "stage.system_status",
    false,
  ),
  createColumnData("HWM", "high_water_mark", "stage.high_water_mark", true),
];

const ClientStagesPage = () => {
  const paginationLimit = PAGINATION_LIMIT;

  let { id } = useParams();

  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState("");
  const [tab, setTab] = useState("stage");

  const [filterStage, setFilterStage] = useState([]);
  const [filterExchange, setFilterExchange] = useState("");
  const [filterTrading, setFilterTrading] = useState("");

  const client = useGetClientByIdQuery(id);

  const stages = useGetStagesListQuery({
    paginationLimit,
    offset,
    client: [id],
    stage: filterStage.map((i) => i.id),
    exchange: filterExchange,
    trading: filterTrading,
    orderBy,
  });

  const orderedRows = stages.data?.rows?.map((row) => {
    const orderedRow = {};
    columns.forEach((column) => {
      orderedRow[column.param] = row[column.param];
    });
    return orderedRow;
  });

  useEffect(() => {
    const tableContainer = document.getElementById("table");

    const onScroll = () => {
      const scrolledToBottom =
        tableContainer.scrollTop + tableContainer.clientHeight >=
        tableContainer.scrollHeight - 1;
      if (scrolledToBottom && !stages.isFetching && !stages.isLoading) {
        if (stages.data.isNextPage) {
          setOffset((prevOffset) => prevOffset + paginationLimit);
        }
      }
    };

    tableContainer.addEventListener("scroll", onScroll);

    return function () {
      tableContainer.removeEventListener("scroll", onScroll);
    };
  }, [offset, stages.isFetching, stages.isLoading]);

  const handleSetFilterStage = (value) => {
    setOffset(0);
    setFilterStage(value);
  };

  const handleSetFilterExchange = (value) => {
    setOffset(0);
    setFilterExchange(value);
  };

  const handleSetOrderBy = (value) => {
    setOffset(0);
    setOrderBy(value);
  };

  const handleSetFilterTrading = (value) => {
    setOffset(0);
    setFilterTrading(value);
  };

  const tableWrapperStyle = {
    maxHeight: `calc(100vh - ${29 + 71}px)`,
    overflowX: "auto",
    border: `1px solid ${COLORS.utility.border}`,
    borderTop: 0,
    borderRadius: '0 0 7px 7px'
  }

  return (
    <Navigation>
      <ClientStagesBreadcrumbs name={"Client page"} />
      <h2>{client?.data?.name}</h2>
      <ClientStagesTabs current={tab} onNavigate={setTab} />

      {tab === "stage" ? (
        <TableContainer
          container={Paper}
          style={{ width: "calc(100vw - 100px)" }}
        >
          <ClientStagesTableFilter
            id={id}
            setFilterStage={handleSetFilterStage}
            setFilterExchange={handleSetFilterExchange}
            setFilterTrading={handleSetFilterTrading}
            cells={columns}
          />
          <div style={tableWrapperStyle} id="table">
            <Table sx={{ maxWidth: "calc(100vw - 100px)" }}>
              <TableHeader
                columns={columns}
                orderBy={orderBy}
                setOrderBy={handleSetOrderBy}
              />
              <ClientStagesTableBody rows={orderedRows} />
            </Table>
          </div>
        </TableContainer>
      ) : (
        <ClientEditPage id={client?.data?.id} name={client?.data?.name} />
      )}
      <Loader
        isLoading={
          stages.isLoading ||
          stages.isFetching ||
          client.isFetching ||
          client.isLoading
        }
      />
    </Navigation>
  );
};

export default ClientStagesPage;
