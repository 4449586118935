import React from "react";
import PropTypes from "prop-types";
import { IconButton, TableCell } from "@mui/material";
import { ActivateIcon, CancelIcon, TrashIcon } from "../../assets";
import StageConnectionCheck from "./StageConnectionCheck";
import { SystemStatus, TradingStatus } from "../../utils/consts";

const StageControls = ({
  stageId,
  tradingStatus = TradingStatus.INACTIVE,
  systemStatus,
  onChageStatus,
  onDeleteStage,
}) => {
  const isAllowedToChange = ![SystemStatus.CONNECTED, SystemStatus.CONFIGURED].includes(systemStatus)

  return (
    <TableCell align="right">
      <IconButton onClick={onChageStatus} disabled={isAllowedToChange}>
        <img
          src={tradingStatus === TradingStatus.ACTIVE ? CancelIcon : ActivateIcon}
          alt="cancel"
          style={{
            filter: isAllowedToChange && "contrast(0)",
            opacity: isAllowedToChange && 0.5,
          }}
        />
      </IconButton>
      <IconButton onClick={onDeleteStage}>
        <img
          src={TrashIcon}
          alt="delete"
          
        />
      </IconButton>
      {systemStatus === SystemStatus.NOT_CONNECTED && (
        <StageConnectionCheck stageId={stageId} />
      )}
    </TableCell>
  );
};

StageControls.propTypes = {
  stageId: PropTypes.string,
  tradingStatus: PropTypes.string,
  onChageStatus: PropTypes.func,
  onDeleteStage: PropTypes.func,
  onEditStage: PropTypes.func,
  systemStatus: PropTypes.string,
};

export default StageControls;
