import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Typography } from "@mui/material";
import {
  FilterButton,
  FilterDropdown,
  InputMultiselect,
  InputSelect,
  TableFilter,
} from "../";
import { GridIcon, FilterIcon } from "../../assets";
import {
  useSearchSignalByStrategyQuery,
  useSearchSignalByTickerQuery,
  useSearchSignalByStageQuery,
} from "../../store/services/apa.api";
import ActionDropdown from "../ActionDropdown";
import { optionsDirection, optionsExchange } from "../../utils/consts";
import ClientFilterInput from "../ClientFilterInput";

const initialFilters = {
  selectedName: [],
  selectedClients: [],
  selectedTicker: [],
  selectedStrategy: [],
  selectedDirection: "",
  selectedExchange: "",
};

const PositionTableFilter = ({
  setCellExclusion,
  excludedCells,
  setSearchByName,
  setSearchByClient,
  setSearchByTicker,
  setSearchByStrategy,
  setSearchByDirection,
  setSearchByExchange,
  selectedRows = [],
  cells,
}) => {
  const [openFilter, setOpenFilter] = useState(false);
  const toggleFilter = () => setOpenFilter((prevState) => !prevState);

  const [queryTicker, setQueryTicker] = useState("");
  const [queryStrategy, setQueryStrategy] = useState("");

  const [filters, setFilters] = useState(initialFilters);

  const searchByNameResult = useSearchSignalByStageQuery();
  const searchByTickerResult = useSearchSignalByTickerQuery({
    name: queryTicker,
  });
  const searchByStrategyResult = useSearchSignalByStrategyQuery({
    name: queryStrategy,
  });

  const getQueryByTicker = useCallback(
    (value) => setQueryTicker(value || ""),
    [],
  );
  const getQueryByStrategy = useCallback(
    (value) => setQueryStrategy(value || ""),
    [],
  );

  const getSearchByName = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedName: value || [],
      })),
    [],
  );

  const getSearchByClient = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedClients: value || [],
      })),
    [],
  );

  const getSearchByTicker = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedTicker: value || [],
      })),
    [],
  );

  const getSearchByStrategy = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedStrategy: value || [],
      })),
    [],
  );

  const onSearchByDirection = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedDirection: value?.name || "",
      })),
    [],
  );

  const onSearchByExchange = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedExchange: value?.name || "",
      })),
    [],
  );

  const optionsName = searchByNameResult?.data
    ? searchByNameResult?.data?.map((item) => ({
      label: item.name,
      ...item,
    }))
    : [];
  const optionsTicker = searchByTickerResult?.data
    ? searchByTickerResult?.data?.map((item) => ({ label: item.name, ...item }))
    : [];
  const optionsStrategy = searchByStrategyResult?.data
    ? searchByStrategyResult?.data?.map((item) => ({
      label: item.name,
      ...item,
    }))
    : [];

  const applyFilters = useCallback(() => {
    setSearchByName(filters.selectedName);
    setSearchByClient(filters.selectedClients);
    setSearchByTicker(filters.selectedTicker);
    setSearchByStrategy(filters.selectedStrategy);
    setSearchByDirection(filters.selectedDirection);
    setSearchByExchange(filters.selectedExchange);
  }, [
    filters,
    setSearchByName,
    setSearchByClient,
    setSearchByTicker,
    setSearchByStrategy,
    setSearchByDirection,
    setSearchByExchange,
  ]);

  const clearFilters = useCallback(() => {
    setFilters(initialFilters);
    setSearchByName([]);
    setSearchByClient([]);
    setSearchByTicker([]);
    setSearchByStrategy([]);
    setSearchByDirection("");
    setSearchByExchange("");
  }, [
    setSearchByName,
    setSearchByClient,
    setSearchByTicker,
    setSearchByStrategy,
    setSearchByDirection,
    setSearchByExchange,
  ]);

  const getFilterExchange = useCallback(() => {
    if (filters.selectedExchange) {
      return optionsExchange.find((o) => o.name === filters.selectedExchange)?.label
    }
    return null;
  }, [filters]);

  const getFilterDirection = useCallback(() => {
    if (filters.selectedDirection) {
      return optionsDirection.find((o) => o.name === filters.selectedDirection)?.label
    }
    return null;
  }, [filters]);

  return (
    <>
      <TableFilter>
        <Grid item>
          <Typography style={{ lineHeight: "40px" }}>
            {selectedRows.length} selected
          </Typography>
        </Grid>
        {selectedRows.length > 0 && (
          <Grid item xs>
            <ActionDropdown selectedRows={selectedRows} />
          </Grid>
        )}
        <Grid item xs />
        <Grid item>
          <FilterButton
            onClick={toggleFilter}
            icon={<img src={FilterIcon} alt="checkbox" />}
          />
        </Grid>
        <Grid item>
          <FilterDropdown
            getSelected={setCellExclusion}
            excludedCells={excludedCells}
            options={cells}
            icon={<img src={GridIcon} alt="grid icon" />}
          />
        </Grid>
      </TableFilter>

      <TableFilter
        style={{ borderRadius: 0, display: openFilter ? "flex" : "none" }}
      >
        <Grid item xs xl={3}>
          <InputMultiselect
            options={optionsName}
            label={"Stage"}
            value={filters.selectedName}
            getSelected={getSearchByName}
            handleInputChange={() => null}
          />
        </Grid>
        <Grid item xs xl={3}>
          <ClientFilterInput
            value={filters.selectedClients}
            getSelected={getSearchByClient}
          />
        </Grid>
        <Grid item xs xl={3}>
          <InputMultiselect
            options={optionsTicker}
            label={"Ticker"}
            value={filters.selectedTicker}
            getSelected={getSearchByTicker}
            handleInputChange={getQueryByTicker}
          />
        </Grid>
        <Grid item xs xl={3}>
          <InputMultiselect
            options={optionsStrategy}
            label={"Strategy"}
            value={filters.selectedStrategy}
            getSelected={getSearchByStrategy}
            handleInputChange={getQueryByStrategy}
          />
        </Grid>
        <Grid item xs xl={3}>
          <InputSelect
            options={optionsDirection}
            label={"Direction"}
            defaultValue={null}
            value={getFilterDirection()}
            getSelected={onSearchByDirection}
          />
        </Grid>
        <Grid item xs xl={3}>
          <InputSelect
            options={optionsExchange}
            label={"Exchange"}
            defaultValue={null}
            value={getFilterExchange()}
            getSelected={onSearchByExchange}
          />
        </Grid>

        <Grid item xs sx={{ textAlign: "end" }}>
          <Button variant="outlined" onClick={clearFilters} sx={{ mr: 2 }}>
            Clear All
          </Button>
          <Button variant="contained" onClick={applyFilters}>
            Apply
          </Button>
        </Grid>
      </TableFilter>
    </>
  );
};

PositionTableFilter.propTypes = {
  setCellExclusion: PropTypes.func,
  excludedCells: PropTypes.array,
  setSearchByName: PropTypes.func,
  setSearchByClient: PropTypes.func,
  setSearchByTicker: PropTypes.func,
  setSearchByStrategy: PropTypes.func,
  setSearchByDirection: PropTypes.func,
  setSearchByExchange: PropTypes.func,
  cells: PropTypes.array,
  selectedRows: PropTypes.array,
};

export default PositionTableFilter;
