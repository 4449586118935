import { COLORS } from "./colors";

export const style = {
  palette: {
    primary: {
      main: COLORS.base.primary,
    },
    secondary: {
      main: COLORS.base.secondary,
    },
    text: {
      primary: COLORS.text.primary,
      secondary: COLORS.text.secondary,
      disabled: COLORS.text.disabled,
    },
    background: {
      default: COLORS.utility.background,
      paper: COLORS.utility.surface,
    },
    divider: COLORS.utility.border,
  },
  typography: {
    fontFamily: "Helvetica",
    body1: {},
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          fontWeight: "700",
          padding: "13px 20px",
          lineHeight: "14px",
          letterSpacing: "1px",
          height: "40px",
        },
        text: {
          "&:hover": {
            backgroundColor: COLORS.icon.selected,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
          height: 40,
        },
        input: {
          fontSize: 14,
          color: COLORS.text.primary,
          "&::placeholder": {
            color: COLORS.text.secondary,
          },
        },
        notchedOutline: {
          border: `1px solid ${COLORS.utility.border} !important`,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 3,
          borderBottomRightRadius: 3,
          border: `1px solid ${COLORS.utility.border}`,
          boxShadow: "none",
          backgroundColor: "white",
        },
        list: {
          padding: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: 40,
          "&:hover": {
            backgroundColor: COLORS.utility.background,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: COLORS.base.primary,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 3,
          borderBottomRightRadius: 3,
          border: `1px solid ${COLORS.utility.border}`,
          boxShadow: "none",
          backgroundColor: "white",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: COLORS.icon.secondary,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          background: "white",
          border: `none`,
          overflowX: "auto",
          borderCollapse: 'separate !important'
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          position: 'sticky',
          top: 0,
          zIndex: 2,
          background: 'white',
        }
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          position: 'sticky',
          bottom: 0,
          zIndex: 3,
          background: 'white',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: COLORS.table.selected,
          },
        },
        head: {
          "&:hover": {
            backgroundColor: "white",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          padding: "0 10px",
          fontSize: 12,
          height: "40px",
          fontWeight: "700",
          borderTop: `1px solid ${COLORS.utility.border}`,
          borderBottom: `1px solid ${COLORS.utility.border}`,
          whiteSpace: "nowrap",
        },
        body: {
          padding: "0 10px",
          fontSize: 12,
          height: "40px",
          borderBottom: `1px solid ${COLORS.utility.border}`,
        },
        footer: {
          padding: "0 10px",
          fontSize: 12,
          height: "40px",
          fontWeight: "700",
          borderTop: `1px solid ${COLORS.utility.border}`,
          whiteSpace: "nowrap",
          color: COLORS.text.primary
        }
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: COLORS.base.tertiary,
          border: "none",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          "&:hover": {
            backgroundColor: COLORS.utility.border,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          border: `1px solid ${COLORS.utility.border}`,
          background: "white",
          boxShadow: "none",
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${COLORS.utility.border}`,
        },
      },
    },
  },
};
