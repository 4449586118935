import React, { useEffect, useState } from "react";
import { Button, Paper, Table, TableContainer } from "@mui/material";
import {
  Loader,
  Navigation,
  PageHeader,
  StageTableBody,
  StageTableFilter,
  TableHeader,
} from "../components";
import { createColumnData } from "../utils/stages";
import { useGetStagesListQuery } from "../store/services/sss.api";
import { StageCreateModal } from "../components";
import { PAGINATION_LIMIT } from "../utils/consts";
import { COLORS } from "../styles/colors";

const columns = [
  createColumnData("Stage id", "id", "stage.id", true),
  createColumnData("Stage name", "name", "stage.name", true),
  createColumnData("Client", "client", "client.name", true),
  createColumnData("Exchange", "exchange", "stage.exchange", true),
  createColumnData("Portfolio", "portfolio", "stage.portfolio", true),
  createColumnData(
    "System status",
    "system_status",
    "stage.system_status",
    false,
  ),
  createColumnData(
    "Trading status",
    "trading_status",
    "stage.trading_status",
    false,
  ),
  createColumnData(
    "Q-active strategies",
    "q_active_strategies",
    "stage.q_active_strategies",
    false,
  ),
  createColumnData("Start date", "start_date", "stage.start_date", false),
  createColumnData("Q-Days", "q_days", "stage.q_days", false),
  createColumnData(
    "Last activity at",
    "last_activity_at",
    "stage.last_activity_at",
    true,
  ),
];

const StagesSystemSettingsPage = () => {
  const paginationLimit = PAGINATION_LIMIT;

  const [openModal, setOpenModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState("");

  const [filterStage, setFilterStage] = useState([]);
  const [filterClient, setFilterClient] = useState([]);
  const [filterExchange, setFilterExchange] = useState("");

  const [excludedCells, setExcludedCells] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const { data, isLoading, isFetching } = useGetStagesListQuery({
    paginationLimit,
    offset,
    stage: filterStage.map((i) => i.id),
    client: filterClient.map((i) => i.id),
    exchange: filterExchange,
    orderBy,
  });

  const handleSelectRow = (selectedRow) => {
    const isSelected = selectedRows.find(
      (currRow) => currRow.id === selectedRow.id,
    );

    if (isSelected) {
      setSelectedRows((prevRows) =>
        prevRows.filter((prevRow) => prevRow.id !== selectedRow.id),
      );
    } else {
      setSelectedRows((prevRows) => [...prevRows, selectedRow]);
    }
  };

  const handleCellExclusion = (excludedCell) => {
    if (excludedCells?.includes(excludedCell)) {
      setExcludedCells(excludedCells.filter((cell) => cell !== excludedCell));
    } else {
      setExcludedCells([...excludedCells, excludedCell]);
    }
  };

  const filteredColumns = columns.filter(
    (item) => !excludedCells.includes(item.param),
  );

  const orderedRows = data?.rows.map((row) => {
    const orderedRow = {};
    columns.forEach((column) => {
      orderedRow[column.param] = row[column.param];
    });
    return orderedRow;
  });

  const filteredRows = orderedRows?.map((obj) =>
    Object.fromEntries(
      Object.entries(obj).filter(([key]) => !excludedCells.includes(key)),
    ),
  );

  useEffect(() => {
    const tableContainer = document.getElementById("table");

    const onScroll = () => {
      const scrolledToBottom =
        tableContainer.scrollTop + tableContainer.clientHeight >=
        tableContainer.scrollHeight - 1;
      if (scrolledToBottom && !isFetching) {
        if (data.isNextPage) {
          setOffset((prevOffset) => prevOffset + paginationLimit);
        }
      }
    };

    tableContainer.addEventListener("scroll", onScroll);

    return function () {
      tableContainer.removeEventListener("scroll", onScroll);
    };
  }, [offset, isFetching]);


  const handleSetOrderBy = (value) => {
    setOffset(0);
    setOrderBy(value);
  };

  const handleSetFilterStage = (value) => {
    setOffset(0);
    setFilterStage(value);
  };

  const handleSetFilterExchange = (value) => {
    setOffset(0);
    setFilterExchange(value);
  };

  const handleSetFilterClient = (value) => {
    setOffset(0);
    setFilterClient(value);
  };

  const tableWrapperStyle = {
    maxHeight: `calc(100vh - ${102 + 71}px)`,
    overflowX: "auto",
    border: `1px solid ${COLORS.utility.border}`,
    borderTop: 0,
    borderRadius: '0 0 7px 7px'
  }

  return (
    <Navigation>
      <StageCreateModal isOpen={openModal} setIsOpen={setOpenModal} />
      <PageHeader
        title={"Stages system settings (SSS)"}
        action={
          <Button onClick={() => setOpenModal(!openModal)} variant="contained">
            Add new stage
          </Button>
        }
      />

      <TableContainer
        container={Paper}
        style={{ width: "calc(100vw - 100px)" }}
      >
        <StageTableFilter
          setCellExclusion={handleCellExclusion}
          excludedCells={excludedCells}
          selectedRows={selectedRows}
          setFilterStage={handleSetFilterStage}
          setFilterExchange={handleSetFilterExchange}
          setFilterClient={handleSetFilterClient}
          cells={columns}
        />
        <div style={tableWrapperStyle} id="table">
          <Table sx={{ maxWidth: "calc(100vw - 100px)" }}>
            <TableHeader
              columns={filteredColumns}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allRows={data?.rows}
              orderBy={orderBy}
              setOrderBy={handleSetOrderBy}
              isHaveAction
              isHaveCheckbox
            />
            <StageTableBody
              rows={filteredRows}
              selectedRows={selectedRows}
              onSelectRow={handleSelectRow}
            />
          </Table>
        </div>
      </TableContainer>

      <Loader isLoading={isLoading || isFetching} />
    </Navigation>
  );
};

export default StagesSystemSettingsPage;
