import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { COLORS } from "../../../styles/colors";
import { CalendarIcon } from "../../../assets";


const inputStyle = {
  alignItems: "center",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: COLORS.utility.border,
  borderRadius: "3px",
  fontSize: "14px",
  fontWeight: 400,
  height: "40px",
  lineHeight: "23px",
  padding: "20px 38px 20px 12px",
};

const iconStyle = {
  position: 'absolute',
  height: 18,
  top: 12,
  right: 10
}

const separatorStyle = {
  position: "absolute",
  borderWidth: 0,
  borderStyle: "solid",
  borderColor: COLORS.utility.border,
  height: 12,
  top: 8,
  right: 36,
  borderRightWidth: "thin",
}

const DateRangeInput = forwardRef(({ value, onClick, ...otherProps }, ref) => (
  <>
    <input
      style={inputStyle}
      value={value}
      onClick={onClick}
      ref={ref}
      {...otherProps}
    />
    <hr style={separatorStyle} />
    <img style={iconStyle} src={CalendarIcon} />
  </>
));

DateRangeInput.displayName = "DateRangeInput";

DateRangeInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  otherProps: PropTypes.object,
};

export default DateRangeInput;
