import React, { useEffect, useState } from "react";
import {
  HistoryTableBody,
  HistoryTableFilter,
  Navigation,
  StageBreadcrumbs,
  TableHeader,
} from "../components";
import {
  useGetStageHistoryByIdQuery,
  useGetStagesByIdQuery,
} from "../store/services/sss.api";
import { useParams } from "react-router-dom";
import { createColumnData } from "../utils/stages";
import { Paper, Table, TableContainer } from "@mui/material";
import StageTabs from "../components/stages/StageTabs";
import { PAGINATION_LIMIT } from "../utils/consts";
import { COLORS } from "../styles/colors";

const columns = [
  createColumnData(
    "Transaction ID",
    "transaction_id",
    "transaction_history.transaction_id",
    true,
  ),
  createColumnData(
    "Date & Time",
    "date_time",
    "transaction_history.date_time",
    true,
  ),
  createColumnData("Asset", "asset", "transaction_history.asset", true),
  createColumnData("Amount", "amount", "transaction_history.amount", true),
  createColumnData(
    "From",
    "from_account",
    "transaction_history.from_account",
    true,
  ),
  createColumnData("To", "to_account", "transaction_history.to_account", true),
  createColumnData("Status", "status", "transaction_history.status", true),
  createColumnData("Info", "info", "transaction_history.info", false),
];

const SssHistoryPage = () => {
  let { id } = useParams();
  const paginationLimit = PAGINATION_LIMIT;

  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState("");
  const [excludedCells, setExcludedCells] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const stage = useGetStagesByIdQuery(id, false);

  const { data, isLoading, isFetching } = useGetStageHistoryByIdQuery({
    id,
    paginationLimit,
    offset,
    orderBy,
    startDate,
    endDate,
  });

  const handleCellExclusion = (excludedCell) => {
    if (excludedCells?.includes(excludedCell)) {
      setExcludedCells(excludedCells.filter((cell) => cell !== excludedCell));
    } else {
      setExcludedCells([...excludedCells, excludedCell]);
    }
  };

  const filteredColumns = columns.filter(
    (item) => !excludedCells.includes(item.param),
  );

  const orderedRows = data?.rows?.map((row) => {
    const orderedRow = {};
    columns.forEach((column) => {
      orderedRow[column.param] = row[column.param];
    });
    return orderedRow;
  });

  const filteredRows = orderedRows?.map((obj) =>
    Object.fromEntries(
      Object.entries(obj).filter(([key]) => {
        return !excludedCells.includes(key);
      }),
    ),
  );

  useEffect(() => {
    const tableContainer = document.getElementById("table");

    const onScroll = () => {
      const scrolledToBottom =
        tableContainer.scrollTop + tableContainer.clientHeight >=
        tableContainer.scrollHeight - 1;
      if (scrolledToBottom && !isFetching) {
        if (data.isNextPage) {
          setOffset((prevOffset) => prevOffset + paginationLimit);
        }
      }
    };

    tableContainer.addEventListener("scroll", onScroll);

    return function () {
      tableContainer.removeEventListener("scroll", onScroll);
    };
  }, [offset, isFetching]);


  const handleSetOrderBy = (value) => {
    setOffset(0);
    setOrderBy(value);
  };

  if (isLoading) {
    return (
      <Navigation>
        <StageBreadcrumbs name={"Transaction History"} />
        Loading ...
      </Navigation>
    );
  }

  const tableWrapperStyle = {
    maxHeight: `calc(100vh - ${174 + 71}px)`,
    overflowX: "auto",
    border: `1px solid ${COLORS.utility.border}`,
    borderTop: 0,
    borderRadius: '0 0 7px 7px'
  }

  return (
    <Navigation>
      <StageBreadcrumbs name={"Transaction History"} />
      <h2>{stage?.data?.name}</h2>
      <StageTabs current="history" id={id} />

      <TableContainer container={Paper}>
        <HistoryTableFilter
          setCellExclusion={handleCellExclusion}
          excludedCells={excludedCells}
          setStartDateFilter={setStartDate}
          setEndDateFilter={setEndDate}
          cells={columns}
        />
        <div style={tableWrapperStyle} id="table">
          <Table>
            <TableHeader
              columns={filteredColumns}
              orderBy={orderBy}
              setOrderBy={handleSetOrderBy}
            />
            <HistoryTableBody rows={filteredRows} />
          </Table>
        </div>
      </TableContainer>
    </Navigation>
  );
};

export default SssHistoryPage;
